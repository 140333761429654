.servicios-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.servicios-header {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  background-color: transparent;
}
.servicios-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.servicios-navlink {
  display: contents;
}
.servicios-image {
  width: 104px;
  height: 114px;
  object-fit: cover;
  text-decoration: none;
}
.servicios-desktop-menu {
  gap: var(--dl-space-space-threeunits);
  display: flex;
}
.servicios-nav {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.servicios-link {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.servicios-link1 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.servicios-link2 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.servicios-link3 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.servicios-link4 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.servicios-link5 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.servicios-register {
  text-decoration: none;
}
.servicios-text {
  font-size: 16px;
  font-family: Open Sans;
  text-transform: uppercase;
}
.servicios-icon {
  width: 16px;
  height: 16px;
}
.servicios-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.servicios-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.servicios-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.servicios-nav1 {
  display: flex;
  z-index: 20;
  align-items: flex-start;
  flex-direction: column;
}
.servicios-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.servicios-text03 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}
.servicios-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.servicios-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.servicios-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.servicios-button-container {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.servicios-login {
  margin-right: var(--dl-space-space-twounits);
}
.servicios-icon-group {
  display: flex;
  z-index: 20;
}
.servicios-icons {
  display: flex;
}
.servicios-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.servicios-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.servicios-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.servicios-background {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 80%;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.servicios-hero {
  background-size: cover;
  background-image: url("/Material web/fondo-ape-serviciosrecurso%201-100-1500h.jpg");
}
.servicios-text09 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 2rem;
  letter-spacing: 2px;
}
.servicios-text10 {
  font-size: 3rem;
  max-width: 450px;
  margin-top: var(--dl-space-space-sixunits);
  text-align: center;
  margin-bottom: var(--dl-space-space-sixunits);
}
.servicios-text11 {
  color: var(--dl-color-gray-white);
}
.servicios-text12 {
  color: var(--dl-color-gray-white);
}
.servicios-text13 {
  color: var(--dl-color-gray-white);
}
.servicios-text14 {
  color: var(--dl-color-gray-white);
}
.servicios-text15 {
  color: var(--dl-color-gray-white);
}
.servicios-container2 {
  flex: 0 0 auto;
  width: 22%;
  display: flex;
  padding: 0.5%;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  border-radius: 100px;
  justify-content: space-between;
  background-color: #D9D9D9;
}
.servicios-icon12 {
  fill: #542ccc;
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
.servicios-textinput {
  border-color: #542ccc;
  border-width: 0px;
  background-color: transparent;
  border-bottom-width: 1px;
}
.servicios-button {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  border-color: var(--dl-color-theme-secondary1);
  background-color: #542ccc;
}
.servicios-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: row;
  padding-bottom: 2%;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(44, 204, 178) 0.00%,rgba(44, 204, 178, 0.7) 100.00%),url("/Material web/fondo-blog-destacado-ape-experience%20(1)-1500w.jpg");
}
.servicios-container4 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.servicios-text16 {
  font-style: normal;
  font-family: Open Sans;
  font-weight: 600;
}
.servicios-button1 {
  font-size: 2rem;
}
.servicios-footer {
  width: 100%;
  height: 588px;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #000000;
}
.servicios-content {
  gap: var(--dl-space-space-threeunits);
  width: 50%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.servicios-details {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.servicios-navlink1 {
  display: contents;
}
.servicios-image1 {
  width: 200px;
  align-self: flex-start;
  object-fit: cover;
  text-decoration: none;
}
.servicios-description {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 18px;
  line-height: 27px;
}
.servicios-content1 {
  gap: var(--dl-space-space-threeunits);
  width: 50%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.servicios-details1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.servicios-text17 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  align-self: flex-start;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  text-transform: uppercase;
}
.servicios-navlink2 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.servicios-text18 {
  font-family: Open Sans;
}
.servicios-navlink3 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.servicios-navlink4 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.servicios-navlink5 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.servicios-navlink6 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.servicios-navlink7 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.servicios-socials {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: row;
}
.servicios-link6 {
  display: contents;
}
.servicios-image2 {
  filter: invert(7);
  text-decoration: none;
}
.servicios-link7 {
  display: contents;
}
.servicios-icon14 {
  width: 24px;
  filter: invert(7);
  height: 24px;
  text-decoration: none;
}
.servicios-link8 {
  display: contents;
}
.servicios-image3 {
  filter: invert(7);
  text-decoration: none;
}
.servicios-copyright {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 18px;
  line-height: 21px;
}
@media(max-width: 1440px) {
  .servicios-header {
    top: 0px;
    left: 0px;
    z-index: 100;
    position: absolute;
    background-color: transparent;
  }
  .servicios-hero {
    justify-content: center;
  }
  .servicios-text09 {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .servicios-text10 {
    font-size: 3rem;
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .servicios-text11 {
    color: #ffffff;
  }
  .servicios-text12 {
    color: inherit;
  }
  .servicios-text13 {
    color: var(--dl-color-gray-white);
    background-color: rgb(241, 140, 13);
  }
  .servicios-text14 {
    color: var(--dl-color-gray-white);
    background-color: rgb(241, 140, 13);
  }
  .servicios-text15 {
    color: #ffffff;
  }
  .servicios-container2 {
    width: 30%;
    padding: 0.5%;
    align-self: center;
    max-height: 100%;
    border-radius: 80px;
    justify-content: space-between;
    background-color: #D9D9D9;
  }
  .servicios-icon12 {
    fill: #542ccc;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .servicios-textinput {
    border-color: #542ccc;
    padding-left: 0px;
    background-color: transparent;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .servicios-button {
    color: white;
    background-color: rgb(84, 44, 204);
  }
  .servicios-container3 {
    gap: var(--dl-space-space-unit);
    padding: 2%;
    flex-wrap: wrap;
  }
  .servicios-container4 {
    width: auto;
  }
  .servicios-text16 {
    width: 720px;
    flex-wrap: wrap;
    font-style: normal;
    font-family: Open Sans;
    font-weight: 600;
    overflow-wrap: break-word;
  }
  .servicios-button1 {
    font-size: 2em;
    align-self: flex-start;
  }
}
@media(max-width: 1200px) {
  .servicios-header {
    background-color: transparent;
  }
  .servicios-navbar {
    padding: var(--dl-space-space-twounits);
  }
  .servicios-text16 {
    font-size: 1.8rem;
  }
}
@media(max-width: 991px) {
  .servicios-navbar {
    padding: var(--dl-space-space-threeunits);
  }
  .servicios-image {
    width: 88px;
    height: 87px;
  }
  .servicios-link {
    font-size: 16px;
  }
  .servicios-link1 {
    font-size: 16px;
  }
  .servicios-link2 {
    font-size: 16px;
  }
  .servicios-link3 {
    font-size: 16px;
  }
  .servicios-link4 {
    font-size: 16px;
  }
  .servicios-link5 {
    font-size: 16px;
  }
  .servicios-register {
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;
  }
  .servicios-text {
    font-size: 14px;
    font-family: Open Sans;
    text-transform: uppercase;
  }
  .servicios-text16 {
    font-size: 1.6rem;
  }
  .servicios-button1 {
    font-size: 1.5rem;
  }
}
@media(max-width: 767px) {
  .servicios-navbar {
    padding: var(--dl-space-space-twounits);
  }
  .servicios-image {
    width: 140px;
    height: 152px;
  }
  .servicios-desktop-menu {
    display: none;
  }
  .servicios-burger-menu {
    display: flex;
  }
  .servicios-icon02 {
    fill: white;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .servicios-nav1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .servicios-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .servicios-text03 {
    color: #000000;
  }
  .servicios-nav2 {
    gap: var(--dl-space-space-unit);
  }
  .servicios-text04 {
    color: #000000;
  }
  .servicios-text05 {
    color: rgb(0, 0, 0);
  }
  .servicios-text06 {
    color: rgb(0, 0, 0);
  }
  .servicios-text07 {
    color: rgb(0, 0, 0);
  }
  .servicios-text08 {
    color: rgb(0, 0, 0);
  }
  .servicios-button-container {
    gap: var(--dl-space-space-unit);
    width: 50%;
    margin-top: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .servicios-login {
    width: 100%;
    border-width: 1px;
    margin-right: 0px;
  }
  .servicios-register1 {
    width: 100%;
    border-width: 1px;
  }
  .servicios-icon-group {
    width: 100%;
    justify-content: flex-start;
  }
  .servicios-container3 {
    padding-top: 2%;
  }
  .servicios-text16 {
    width: 480px;
    font-size: 1.6rem;
    text-align: left;
  }
  .servicios-footer {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .servicios-content {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .servicios-description {
    font-size: 14px;
    line-height: 21px;
  }
  .servicios-content1 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .servicios-socials {
    gap: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .servicios-image {
    width: var(--dl-size-size-medium);
    height: auto;
  }
  .servicios-icon02 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .servicios-mobile-menu {
    padding: 16px;
  }
  .servicios-button-container {
    width: 100%;
  }
  .servicios-icon-group {
    justify-content: center;
  }
  .servicios-container2 {
    width: 100%;
    margin-top: var(--dl-space-space-halfunit);
  }
  .servicios-container3 {
    align-self: center;
    text-align: center;
    align-content: center;
    justify-content: center;
  }
  .servicios-container4 {
    width: 100%;
    align-items: center;
  }
  .servicios-text16 {
    width: var(--dl-size-size-xxlarge);
    text-align: center;
  }
  .servicios-button1 {
    flex-direction: column;
  }
  .servicios-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .servicios-content {
    width: 100%;
    max-width: 100%;
    align-self: center;
    align-items: center;
  }
  .servicios-details {
    max-width: 100%;
  }
  .servicios-image1 {
    align-self: center;
  }
  .servicios-description {
    font-size: 18px;
    text-align: center;
  }
  .servicios-content1 {
    width: 100%;
    align-self: center;
    align-items: center;
  }
  .servicios-text17 {
    width: 100%;
    text-align: center;
  }
  .servicios-navlink2 {
    width: 100%;
    text-align: center;
  }
  .servicios-navlink3 {
    width: 100%;
    text-align: center;
  }
  .servicios-navlink4 {
    width: 100%;
    text-align: center;
  }
  .servicios-navlink5 {
    width: 100%;
    text-align: center;
  }
  .servicios-navlink6 {
    width: 100%;
    text-align: center;
  }
  .servicios-navlink7 {
    width: 100%;
    text-align: center;
  }
  .servicios-socials {
    justify-content: center;
  }
  .servicios-copyright {
    text-align: center;
  }
}
