.solicitar-servicio-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.solicitar-servicio-header {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #542ccc;
}
.solicitar-servicio-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.solicitar-servicio-navlink {
  display: contents;
}
.solicitar-servicio-image {
  width: 104px;
  height: 114px;
  object-fit: cover;
  text-decoration: none;
}
.solicitar-servicio-desktop-menu {
  gap: var(--dl-space-space-threeunits);
  display: flex;
}
.solicitar-servicio-nav {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.solicitar-servicio-link {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.solicitar-servicio-link01 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.solicitar-servicio-link02 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.solicitar-servicio-link03 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.solicitar-servicio-link04 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.solicitar-servicio-link05 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.solicitar-servicio-register {
  text-decoration: none;
}
.solicitar-servicio-text {
  font-size: 16px;
  font-family: Open Sans;
  text-transform: uppercase;
}
.solicitar-servicio-icon {
  width: 16px;
  height: 16px;
}
.solicitar-servicio-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.solicitar-servicio-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.solicitar-servicio-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.solicitar-servicio-nav1 {
  display: flex;
  z-index: 20;
  align-items: flex-start;
  flex-direction: column;
}
.solicitar-servicio-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.solicitar-servicio-text03 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}
.solicitar-servicio-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.solicitar-servicio-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.solicitar-servicio-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.solicitar-servicio-button-container {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.solicitar-servicio-login {
  margin-right: var(--dl-space-space-twounits);
}
.solicitar-servicio-background {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 80%;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.solicitar-servicio-socials {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: row;
}
.solicitar-servicio-link06 {
  display: contents;
}
.solicitar-servicio-image1 {
  filter: invert(7);
  text-decoration: none;
}
.solicitar-servicio-link07 {
  display: contents;
}
.solicitar-servicio-icon06 {
  width: 24px;
  filter: invert(7);
  height: 24px;
  text-decoration: none;
}
.solicitar-servicio-link08 {
  display: contents;
}
.solicitar-servicio-image2 {
  filter: invert(7);
  text-decoration: none;
}
.solicitar-servicio-container2 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  padding-bottom: 5%;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgba(44, 62, 80, 0.6) 0.00%,rgba(44, 62, 80, 0.8) 100.00%),url("https://images.unsplash.com/photo-1556075798-4825dfaaf498?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxjb2RlfGVufDB8fHx8MTY5NDc5NTUwNnww&ixlib=rb-4.0.3&w=1500");
}
.solicitar-servicio-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.solicitar-servicio-container4 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.solicitar-servicio-container6 {
  display: contents;
}
.solicitar-servicio-container8 {
  display: contents;
}
.solicitar-servicio-footer {
  width: 100%;
  height: 588px;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #000000;
}
.solicitar-servicio-content {
  gap: var(--dl-space-space-threeunits);
  width: 50%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.solicitar-servicio-details {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.solicitar-servicio-navlink1 {
  display: contents;
}
.solicitar-servicio-image3 {
  width: 200px;
  align-self: flex-start;
  object-fit: cover;
  text-decoration: none;
}
.solicitar-servicio-description {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 18px;
  line-height: 27px;
}
.solicitar-servicio-content1 {
  gap: var(--dl-space-space-threeunits);
  width: 50%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.solicitar-servicio-details1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.solicitar-servicio-text12 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  align-self: flex-start;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  text-transform: uppercase;
}
.solicitar-servicio-navlink2 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.solicitar-servicio-text13 {
  font-family: Open Sans;
}
.solicitar-servicio-navlink3 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.solicitar-servicio-navlink4 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.solicitar-servicio-navlink5 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.solicitar-servicio-navlink6 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.solicitar-servicio-navlink7 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.solicitar-servicio-socials1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: row;
}
.solicitar-servicio-link09 {
  display: contents;
}
.solicitar-servicio-image4 {
  filter: invert(7);
  text-decoration: none;
}
.solicitar-servicio-link10 {
  display: contents;
}
.solicitar-servicio-icon08 {
  width: 24px;
  filter: invert(7);
  height: 24px;
  text-decoration: none;
}
.solicitar-servicio-link11 {
  display: contents;
}
.solicitar-servicio-image5 {
  filter: invert(7);
  text-decoration: none;
}
.solicitar-servicio-copyright {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 18px;
  line-height: 21px;
}
@media(max-width: 1200px) {
  .solicitar-servicio-header {
    background-color: #542ccc;
  }
}
@media(max-width: 991px) {
  .solicitar-servicio-navbar {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .solicitar-servicio-image {
    width: 88px;
    height: 87px;
  }
  .solicitar-servicio-link {
    font-size: 16px;
  }
  .solicitar-servicio-link01 {
    font-size: 16px;
  }
  .solicitar-servicio-link02 {
    font-size: 16px;
  }
  .solicitar-servicio-link03 {
    font-size: 16px;
  }
  .solicitar-servicio-link04 {
    font-size: 16px;
  }
  .solicitar-servicio-link05 {
    font-size: 16px;
  }
  .solicitar-servicio-register {
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;
  }
  .solicitar-servicio-text {
    font-size: 14px;
    font-family: Open Sans;
    text-transform: uppercase;
  }
}
@media(max-width: 767px) {
  .solicitar-servicio-navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .solicitar-servicio-image {
    width: var(--dl-size-size-medium);
    height: auto;
  }
  .solicitar-servicio-desktop-menu {
    display: none;
  }
  .solicitar-servicio-burger-menu {
    display: flex;
  }
  .solicitar-servicio-icon02 {
    fill: white;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .solicitar-servicio-nav1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .solicitar-servicio-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .solicitar-servicio-text03 {
    color: #000000;
  }
  .solicitar-servicio-nav2 {
    gap: var(--dl-space-space-unit);
  }
  .solicitar-servicio-text04 {
    color: #000000;
  }
  .solicitar-servicio-text05 {
    color: rgb(0, 0, 0);
  }
  .solicitar-servicio-text06 {
    color: rgb(0, 0, 0);
  }
  .solicitar-servicio-text07 {
    color: rgb(0, 0, 0);
  }
  .solicitar-servicio-text08 {
    color: rgb(0, 0, 0);
  }
  .solicitar-servicio-text09 {
    color: rgb(0, 0, 0);
  }
  .solicitar-servicio-text10 {
    color: rgb(0, 0, 0);
  }
  .solicitar-servicio-button-container {
    gap: var(--dl-space-space-unit);
    width: 50%;
    margin-top: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .solicitar-servicio-login {
    width: 100%;
    border-width: 1px;
    margin-right: 0px;
  }
  .solicitar-servicio-register1 {
    width: 100%;
    border-width: 1px;
  }
  .solicitar-servicio-socials {
    gap: var(--dl-space-space-twounits);
  }
  .solicitar-servicio-footer {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .solicitar-servicio-content {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .solicitar-servicio-description {
    font-size: 14px;
    line-height: 21px;
  }
  .solicitar-servicio-content1 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .solicitar-servicio-socials1 {
    gap: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .solicitar-servicio-navbar {
    padding: var(--dl-space-space-oneandhalfunits);
    padding-right: 24px;
  }
  .solicitar-servicio-image {
    width: auto;
    height: 90px;
  }
  .solicitar-servicio-mobile-menu {
    height: 100%;
    padding: 16px;
  }
  .solicitar-servicio-text03 {
    color: rgb(0, 0, 0);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;
  }
  .solicitar-servicio-icon04 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .solicitar-servicio-nav2 {
    width: 100%;
  }
  .solicitar-servicio-text04 {
    width: 100%;
    font-size: 24px;
    text-align: center;
  }
  .solicitar-servicio-text05 {
    width: 100%;
    font-size: 24px;
    text-align: center;
  }
  .solicitar-servicio-text06 {
    width: 100%;
    font-size: 24px;
    text-align: center;
  }
  .solicitar-servicio-text07 {
    width: 100%;
    font-size: 24px;
    text-align: center;
  }
  .solicitar-servicio-text08 {
    width: 100%;
    font-size: 24px;
    text-align: center;
  }
  .solicitar-servicio-text09 {
    width: 100%;
    font-size: 24px;
    text-align: center;
  }
  .solicitar-servicio-text10 {
    width: 100%;
    padding: var(--dl-space-space-unit);
    font-size: 24px;
    text-align: center;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 100px;
    background-color: #D9D9D9;
  }
  .solicitar-servicio-button-container {
    width: 100%;
  }
  .solicitar-servicio-login {
    font-size: 22px;
  }
  .solicitar-servicio-register1 {
    font-size: 22px;
  }
  .solicitar-servicio-socials {
    justify-content: center;
  }
  .solicitar-servicio-image1 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .solicitar-servicio-icon06 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .solicitar-servicio-image2 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .solicitar-servicio-container2 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .solicitar-servicio-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .solicitar-servicio-content {
    width: 100%;
    max-width: 100%;
    align-self: center;
    align-items: center;
  }
  .solicitar-servicio-details {
    max-width: 100%;
  }
  .solicitar-servicio-image3 {
    align-self: center;
  }
  .solicitar-servicio-description {
    font-size: 18px;
    text-align: center;
  }
  .solicitar-servicio-content1 {
    width: 100%;
    align-self: center;
    align-items: center;
  }
  .solicitar-servicio-text12 {
    width: 100%;
    text-align: center;
  }
  .solicitar-servicio-navlink2 {
    width: 100%;
    text-align: center;
  }
  .solicitar-servicio-navlink3 {
    width: 100%;
    text-align: center;
  }
  .solicitar-servicio-navlink4 {
    width: 100%;
    text-align: center;
  }
  .solicitar-servicio-navlink5 {
    width: 100%;
    text-align: center;
  }
  .solicitar-servicio-navlink6 {
    width: 100%;
    text-align: center;
  }
  .solicitar-servicio-navlink7 {
    width: 100%;
    text-align: center;
  }
  .solicitar-servicio-socials1 {
    justify-content: center;
  }
  .solicitar-servicio-copyright {
    text-align: center;
  }
}
