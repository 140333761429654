.blog-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.blog-header {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #542ccc;
}
.blog-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.blog-navlink {
  display: contents;
}
.blog-image {
  width: 104px;
  height: 114px;
  object-fit: cover;
  text-decoration: none;
}
.blog-desktop-menu {
  gap: var(--dl-space-space-threeunits);
  display: flex;
}
.blog-nav {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.blog-link {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.blog-link1 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.blog-link2 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.blog-link3 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.blog-link4 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.blog-link5 {
  font-size: 18px;
  font-family: Open Sans;
  text-transform: uppercase;
  text-decoration: none;
}
.blog-register {
  text-decoration: none;
}
.blog-text {
  font-size: 16px;
  font-family: Open Sans;
  text-transform: uppercase;
}
.blog-icon {
  width: 16px;
  height: 16px;
}
.blog-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.blog-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.blog-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.blog-nav1 {
  display: flex;
  z-index: 20;
  align-items: flex-start;
  flex-direction: column;
}
.blog-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.blog-text03 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}
.blog-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.blog-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-button-container {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.blog-login {
  margin-right: var(--dl-space-space-twounits);
}
.blog-icon-group {
  display: flex;
  z-index: 20;
}
.blog-icons {
  display: flex;
}
.blog-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.blog-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.blog-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.blog-background {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 80%;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.blog-container02 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  padding-bottom: 5%;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgba(44, 62, 80, 0.6) 0.00%,rgba(44, 62, 80, 0.8) 100.00%),url("https://images.unsplash.com/photo-1556075798-4825dfaaf498?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxjb2RlfGVufDB8fHx8MTY5NDc5NTUwNnww&ixlib=rb-4.0.3&w=1500");
}
.blog-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.blog-container04 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.blog-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: row;
  padding-bottom: 2%;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(44, 204, 178) 0.00%,rgba(44, 204, 178, 0.7) 100.00%),url("/Material web/fondo-blog-destacado-ape-experience%20(1)-1500w.jpg");
}
.blog-container06 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-text10 {
  font-style: normal;
  font-family: Open Sans;
  font-weight: 600;
}
.blog-button {
  font-size: 2rem;
}
.blog-container08 {
  display: contents;
}
.blog-container10 {
  display: contents;
}
.blog-footer {
  width: 100%;
  height: 588px;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #000000;
}
.blog-content {
  gap: var(--dl-space-space-threeunits);
  width: 50%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.blog-details {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.blog-navlink1 {
  display: contents;
}
.blog-image1 {
  width: 200px;
  align-self: flex-start;
  object-fit: cover;
  text-decoration: none;
}
.blog-description {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 18px;
  line-height: 27px;
}
.blog-content1 {
  gap: var(--dl-space-space-threeunits);
  width: 50%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.blog-details1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.blog-text11 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  align-self: flex-start;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  text-transform: uppercase;
}
.blog-navlink2 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.blog-text12 {
  font-family: Open Sans;
}
.blog-navlink3 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.blog-navlink4 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.blog-navlink5 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.blog-navlink6 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.blog-navlink7 {
  color: var(--dl-color-gray-white);
  line-height: 1.15;
  text-decoration: none;
}
.blog-socials {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: row;
}
.blog-link6 {
  display: contents;
}
.blog-image2 {
  filter: invert(7);
  text-decoration: none;
}
.blog-link7 {
  display: contents;
}
.blog-icon12 {
  width: 24px;
  filter: invert(7);
  height: 24px;
  text-decoration: none;
}
.blog-link8 {
  display: contents;
}
.blog-image3 {
  filter: invert(7);
  text-decoration: none;
}
.blog-copyright {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 18px;
  line-height: 21px;
}
@media(max-width: 1440px) {
  .blog-container05 {
    gap: var(--dl-space-space-unit);
    padding: 2%;
    flex-wrap: wrap;
  }
  .blog-container06 {
    width: auto;
  }
  .blog-text10 {
    width: 720px;
    flex-wrap: wrap;
    font-style: normal;
    font-family: Open Sans;
    font-weight: 600;
    overflow-wrap: break-word;
  }
  .blog-button {
    font-size: 2em;
    align-self: flex-start;
  }
}
@media(max-width: 1200px) {
  .blog-header {
    background-color: #542ccc;
  }
  .blog-text10 {
    font-size: 1.8rem;
  }
}
@media(max-width: 991px) {
  .blog-image {
    width: 88px;
    height: 87px;
  }
  .blog-link {
    font-size: 16px;
  }
  .blog-link1 {
    font-size: 16px;
  }
  .blog-link2 {
    font-size: 16px;
  }
  .blog-link3 {
    font-size: 16px;
  }
  .blog-link4 {
    font-size: 16px;
  }
  .blog-link5 {
    font-size: 16px;
  }
  .blog-register {
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;
  }
  .blog-text {
    font-size: 14px;
    font-family: Open Sans;
    text-transform: uppercase;
  }
  .blog-text10 {
    font-size: 1.6rem;
  }
  .blog-button {
    font-size: 1.5rem;
  }
}
@media(max-width: 767px) {
  .blog-image {
    width: 140px;
    height: 152px;
  }
  .blog-desktop-menu {
    display: none;
  }
  .blog-burger-menu {
    display: flex;
  }
  .blog-icon02 {
    fill: white;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .blog-nav1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .blog-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .blog-text03 {
    color: #000000;
  }
  .blog-nav2 {
    gap: var(--dl-space-space-unit);
  }
  .blog-text04 {
    color: #000000;
  }
  .blog-text05 {
    color: rgb(0, 0, 0);
  }
  .blog-text06 {
    color: rgb(0, 0, 0);
  }
  .blog-text07 {
    color: rgb(0, 0, 0);
  }
  .blog-text08 {
    color: rgb(0, 0, 0);
  }
  .blog-button-container {
    gap: var(--dl-space-space-unit);
    width: 50%;
    margin-top: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .blog-login {
    width: 100%;
    border-width: 1px;
    margin-right: 0px;
  }
  .blog-register1 {
    width: 100%;
    border-width: 1px;
  }
  .blog-icon-group {
    width: 100%;
    justify-content: flex-start;
  }
  .blog-container05 {
    padding-top: 2%;
  }
  .blog-text10 {
    width: 480px;
    font-size: 1.6rem;
    text-align: left;
  }
  .blog-footer {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-content {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .blog-description {
    font-size: 14px;
    line-height: 21px;
  }
  .blog-content1 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .blog-socials {
    gap: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .blog-navbar {
    padding: var(--dl-space-space-oneandhalfunits);
    padding-right: 24px;
  }
  .blog-image {
    width: auto;
    height: 90px;
  }
  .blog-mobile-menu {
    padding: 16px;
  }
  .blog-button-container {
    width: 100%;
  }
  .blog-icon-group {
    justify-content: center;
  }
  .blog-container02 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .blog-container05 {
    align-self: center;
    text-align: center;
    padding-top: 5%;
    align-content: center;
    flex-direction: column;
    padding-bottom: 5%;
  }
  .blog-text10 {
    width: 311px;
    font-size: 1.2rem;
    align-self: center;
    text-align: center;
  }
  .blog-button {
    align-self: center;
  }
  .blog-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .blog-content {
    width: 100%;
    max-width: 100%;
    align-self: center;
    align-items: center;
  }
  .blog-details {
    max-width: 100%;
  }
  .blog-image1 {
    align-self: center;
  }
  .blog-description {
    font-size: 18px;
    text-align: center;
  }
  .blog-content1 {
    width: 100%;
    align-self: center;
    align-items: center;
  }
  .blog-text11 {
    width: 100%;
    text-align: center;
  }
  .blog-navlink2 {
    width: 100%;
    text-align: center;
  }
  .blog-navlink3 {
    width: 100%;
    text-align: center;
  }
  .blog-navlink4 {
    width: 100%;
    text-align: center;
  }
  .blog-navlink5 {
    width: 100%;
    text-align: center;
  }
  .blog-navlink6 {
    width: 100%;
    text-align: center;
  }
  .blog-navlink7 {
    width: 100%;
    text-align: center;
  }
  .blog-socials {
    justify-content: center;
  }
  .blog-copyright {
    text-align: center;
  }
}
