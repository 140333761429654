.pricing14-pricing23 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing14-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing14-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing14-text {
  text-align: center;
}
.pricing14-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-text01 {
  text-align: center;
}
.pricing14-text02 {
  text-align: center;
}
.pricing14-container {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing14-column {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.pricing14-price {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price01 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-text03 {
  font-style: normal;
  font-weight: 600;
}
.pricing14-text04 {
  font-size: 48px;
}
.pricing14-list {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button {
  width: 100%;
  transition: 0.3s;
}
.pricing14-button:hover {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
}
.pricing14-column1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: #e7e7e7;
}
.pricing14-price02 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price03 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-text10 {
  font-style: normal;
  font-weight: 600;
}
.pricing14-text11 {
  font-size: 48px;
}
.pricing14-list1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button1 {
  width: 100%;
}
.pricing14-column2 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: #542CCC;
}
.pricing14-price04 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price05 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-text18 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  font-weight: 600;
}
.pricing14-text19 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 48px;
}
.pricing14-text20 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
.pricing14-list2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-icon14 {
  fill: #D9D9D9;
}
.pricing14-text21 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
.pricing14-list-item08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-icon16 {
  fill: #D9D9D9;
}
.pricing14-text22 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
.pricing14-list-item09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-icon18 {
  fill: #D9D9D9;
}
.pricing14-text23 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
.pricing14-list-item10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-icon20 {
  fill: #D9D9D9;
}
.pricing14-text24 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
.pricing14-list-item11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-icon22 {
  fill: #D9D9D9;
}
.pricing14-text25 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
}
.pricing14-button2 {
  width: 100%;
}
.pricing14-container1 {
  gap: 32px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing14-column3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.pricing14-price06 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price07 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-text27 {
  font-style: normal;
  font-weight: 600;
}
.pricing14-text28 {
  font-size: 48px;
}
.pricing14-list3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button3 {
  width: 100%;
}
.pricing14-column4 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent1);
}
.pricing14-price08 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price09 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-text34 {
  font-style: normal;
  font-weight: 600;
}
.pricing14-text35 {
  font-size: 48px;
}
.pricing14-list4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button4 {
  width: 100%;
}
.pricing14-column5 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent2);
}
.pricing14-price10 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-price11 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing14-text42 {
  font-style: normal;
  font-weight: 600;
}
.pricing14-text43 {
  font-size: 48px;
}
.pricing14-list5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing14-list-item19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item20 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item22 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-list-item23 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing14-button5 {
  width: 100%;
}
@media(max-width: 1440px) {
  .pricing14-text01 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    padding: var(--dl-space-space-unit);
    font-family: Source Sans Pro;
    background-color: #F18C0D;
  }
  .pricing14-column {
    border-color: var(--dl-color-gray-900);
  }
  .pricing14-text04 {
    font-family: Source Sans Pro;
  }
  .pricing14-text05 {
    text-align: center;
  }
  .pricing14-button {
    fill: rgb(84, 44, 204);
    color: rgb(84, 44, 204);
    border-color: rgb(84, 44, 204);
  }
  .pricing14-column1 {
    border-color: var(--dl-color-gray-900);
    background-color: #f7f7f7;
  }
  .pricing14-text11 {
    font-family: Source Sans Pro;
  }
  .pricing14-text12 {
    text-align: center;
  }
  .pricing14-column2 {
    background-color: #542CCC;
  }
  .pricing14-text18 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
  }
  .pricing14-text19 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-family: Source Sans Pro;
  }
  .pricing14-text20 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    text-align: center;
  }
  .pricing14-icon14 {
    fill: #D9D9D9;
  }
  .pricing14-text21 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
  }
  .pricing14-icon16 {
    fill: #D9D9D9;
  }
  .pricing14-text22 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
  }
  .pricing14-icon18 {
    fill: #D9D9D9;
  }
  .pricing14-text23 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
  }
  .pricing14-icon20 {
    fill: #D9D9D9;
  }
  .pricing14-text24 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
  }
  .pricing14-icon22 {
    fill: #D9D9D9;
  }
  .pricing14-text25 {
    fill: var(--dl-color-theme-secondary2);
    color: var(--dl-color-theme-secondary2);
  }
}
@media(max-width: 991px) {
  .pricing14-container {
    flex-direction: column;
  }
  .pricing14-column2 {
    width: 100%;
  }
  .pricing14-container1 {
    flex-direction: column;
  }
  .pricing14-column5 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pricing14-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
